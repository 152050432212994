<template>
  <v-container>
    <Events :events="events" :pastEvents="pastEvents" :noEvents="false" @reserve="reserveEvent" />
      <NeoModal
        v-if="showAdviseModal"
        :max-width="440"
        title-header="Confirm"
        @close="cancel"
      >
        <v-card-text class="pb-8">
          <h2 class="pa-4 text-center">
            {{
              unregister ? $t("unregisterEventModal") : guestlist ? $t("reserveEventModal") : $t("registerEventModal")
            }}
          </h2>
          <v-divider />
          <div>
            <a :href="url" target="_blank" class="flex-grow-1">
              <h3 class="event-title">
                {{ eventName }}
              </h3>
            </a>
          </div>
          <div class="pl-4">
            {{ $t("atTheNameOf") }}:
            <strong>{{ `${$store.state.user.displayName}` }}</strong>
          </div>

          <div class="pl-4">
            {{ $t("venue") }}: <strong>{{ eventVenue }}</strong>
          </div>
          <div class="pl-4">
            {{ $t("date") }}: <strong>{{ eventDate }}</strong>
          </div>
        </v-card-text>
        <template slot="actions" class="pt-0">
          <div class="footer-parent">
            <div class="checkout-advise-modal-footer">
              <v-btn @click="cancel" outlined color="primary">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn :disabled="waiting" color="primary" @click="confirm"
                >{{ unregister ? $t("unregister") :  guestlist ? $t("reserve") : $t("attend") }}
                <v-icon size="32" v-if="waiting" class="ml-1 waiting">
                  mdi-tire
                </v-icon>
              </v-btn>
            </div>

            <p class="reCAPTCHA-disclaimer text-center">
              This site is protected by reCAPTCHA and the Google
              <a target="_blank" href="https://policies.google.com/privacy"
                >Privacy Policy</a
              >
              and
              <a target="_blank" href="https://policies.google.com/terms"
                >Terms of Service</a
              >
              apply.
            </p>
          </div>
        </template>
        <VueRecaptcha
          class="recaptcha"
          :sitekey="keyRecaptcha"
          @verify="onVerify"
          @expired="onExpired"
          ref="recaptcha"
          size="invisible"
          badge="inline"
          type="contact"
          :loadRecaptchaScript="false"
          theme="light"
        ></VueRecaptcha>
      </NeoModal>
  </v-container>
</template>

<script>
import { auth } from "@/helpers/firebase";
import Events from "@/components/Templates/Events";
const NeoModal = () =>
  import(/* webpackChunkName: "NeoModal" */ "@/components/Molecules/NeoModal");
import EventsDS from "@/services/EventsDS";
import { VueRecaptcha } from "vue-recaptcha";
import { firebase } from "@/helpers/firebase";

export default {
  name: "vEventsPrivate",
  components: {
    Events,
    NeoModal,
    VueRecaptcha,
  },
  data: () => ({
    showAdviseModal: false,
    events: [],
    pastEvents: [],
    keyRecaptcha: process.env.VUE_APP_RECAPTCHA_SITE,
    sent: false,
    waiting: false,
    user: auth.currentUser,
    reserved: [],
  }),
  methods: {
    confirm() {
      this.$refs.recaptcha.execute();
    },
    cancel() {
      this.showAdviseModal = false;
    },
    reserveEvent(info) {
      this.eventId = info.eventId;
      this.eventName = info.name;
      this.eventVenue = info.venue.name;
      this.eventDate = info.date;
      this.url = info.url;
      this.eventAddress = info.location.address;
      this.eventMap = info.location.gmaps;
      this.startdate = info.startdate;
      this.unregister = info.unregister;

      this.reservation = {
        organizer: info.organizer,
        location: info.location,
        url: info.url,
        entrance: info.entrance,
        startdate: info.startdate,
        description: info.description,
        name: info.name,
        venue: info.venue,
        image: info.image,
        guestlist: info.guestlist,
        
      }
      this.showAdviseModal = true;
    },
    onVerify(response) {
      if (response !== "") {
        if (this.unregister) this.unregisterUser();
        else this.sendMail();
      } else {
        return false;
      }
    },
    onExpired() {
      this.resetRecaptcha();
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset();
      this.disabled = true;
    },
    unregisterUser() {
      this.waiting = true;
      const reservationInfo = {
        eventId: this.eventId,
      };

      firebase
        .app()
        .functions("asia-northeast1")
        .httpsCallable("events-unregister")(reservationInfo)
        .then((res) => {
          console.log(res);
          this.waiting = false;
          const index = this.events.findIndex((object) => {
            return object.id === this.eventId;
          });
          const event = [...this.events];
          event[index] = { ...event[index], reserved: false };
          this.events = [...event];
          this.showAdviseModal = false;
        })
        .catch(() => {
          // Getting the Error details.
          this.waiting = false;
        });
    },
    sendMail() {
      this.waiting = true;
      const reservationInfo = {
        user_name: this.$store.state.user.displayName,
        email: this.$store.state.user.email,
        event_name: this.eventName,
        venue_name: this.eventVenue,
        venue_address: this.eventAddress,
        event_date: this.eventDate,
        maps_url: this.eventMap,
        event_url: this.url,
        eventId: this.eventId,
        startdate: this.startdate,
        reservation: this.reservation,
        lang: this.$store.state.lang,
      };

      firebase
        .app()
        .functions("asia-northeast1")
        .httpsCallable("events-register")(reservationInfo)
        .then((res) => {
          console.log(res);
          this.waiting = false;
          const index = this.events.findIndex((object) => {
            return object.id === this.eventId;
          });
          const event = [...this.events];
          event[index] = { ...event[index], reserved: true };
          this.events = [...event];
          this.showAdviseModal = false;
        })
        .catch(() => {
          // Getting the Error details.
          this.waiting = false;
        });
    },
  },
  beforeMount() {
    const reservations = EventsDS.reserved();
    reservations.then((docsR) => {
      docsR.forEach((docR) => {
        const info = docR.data();
        info.id = docR.id;
        info.reserved = true;
        if (info.startdate < Date.now()) this.pastEvents.push(info);
        else this.events.push(info);
      });
    });

    // If user is authenticated, get the list of registered events
  },
};
</script>

<style lang="scss" scoped>
.event-title {
  padding: 10px 10px 20px;
  text-align: center;
  color: var(--color-primary);
}
.recaptcha {
  visibility: hidden;
  position: absolute;
}
.grecaptcha-badge {
  visibility: hidden !important;
  display: none !important;
}
.reCAPTCHA-disclaimer {
  font-size: var(--font-size-super-small);
  padding: 0px 10px;
}
.footer-parent {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
}
.checkout-advise-modal-footer {
  display: flex;
  @media only screen and (max-width: 599px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 16px;
    > button + button {
      margin-top: 16px;
    }
  }
  @media only screen and (min-width: 600px) {
    flex-direction: row;
    padding: 0px 0px 16px;
    > button + button {
      margin-left: 16px;
    }
  }
}
</style>
